/***
 *
 *   CARD
 *   Universal container for grouping UI components together
 *
 *   PROPS
 *   title: title string (optional)
 *   loading: boolean to toggle the loading animation (optional)
 *   shadow: apply a box shadow
 *   center: center align the card
 *   noPadding: remove the padding
 *   restrictWidth: restrict the width of the card on large screens
 *
 **********/

import { Loader, ClassHelper, Button } from "components/lib";
import Style from "./chatroomCard.module.scss";
import { useNavigate } from "react-router-dom";

export function ChatroomCard(props) {
  const navigate = useNavigate();

  const cardStyle = ClassHelper(Style, {
    center: props.center,
    loading: props.loading,
    className: props.className,
    last: props.last,
  });

  // create to the correct edit URL
  const path = props.type === "workspace" ? "space" : "assistant";
  const editURL = `/ai/create/${path}/${props.chat.id}`;
  const chatURL = `/ai/space/${props.chat.id}`;

  return (
    <section className={cardStyle} onClick={() => navigate(chatURL)}>
      {props.edit && (
        <Button
          className={Style.editBtn}
          icon="edit"
          action={() => navigate(editURL)}
        />
      )}

      <div className={`${Style.thumbnail} ${Style[props.type]}`}>
        {" "}
        <img src={props.chat.thumbnail_url} />
      </div>

      <div className={Style.progressBar}></div>

      <div className={Style.content}>
        <h3 className={Style.title}>{props.chat.title}</h3>
        <div className={Style.description}>{props.chat.description}</div>
      </div>

      {props.loading ? <Loader /> : props.children}
    </section>
  );
}
