/***
 *
 *   ACCOUNT
 *   Index page for account functions
 *
 **********/

import React, { useContext, useState, useEffect } from "react";

import {
  AuthContext,
  ViewContext,
  useNavigate,
  Animate,
  Button,
  AccountCard,
  Event,
} from "components/lib";

export function Account(props) {
  const Style = {
    subtitle: "font-bold mt-4",
    link: "text-blue-500 font-semibold mt-3 inline-block",
    close: "btnCloseAccount",
  };

  const authContext = useContext(AuthContext);
  const navigate = useNavigate();
  const viewContext = useContext(ViewContext);

  const [state, setState] = useState([{ loading: true }]);

  function closeAccount() {
    viewContext.modal.show(
      {
        title: "Close Your Account",
        form: {},
        buttonText: "Close Account",
        url: authContext.permission.owner ? "/api/account" : "/api/user",
        method: "DELETE",
        destructive: true,
        text:
          "Are you sure you want to delete your account? " +
          "You will lose all of your data and this can not be undone.",
      },
      () => {
        // destory user
        Event("closed_account");
        localStorage.clear();
        navigate("/signup");
      }
    );
  }

  return (
    <>
      <Animate>
        <AccountCard className="account" title="Account Management">
          <p>
            Profile management provides you with the ability to update your
            personal information, such as your name and email address.
            Additionally, you can manage your password and security settings, as
            well as change your payment information if needed.
          </p>

          <Button
            url="/account/profile"
            text={"Profile"}
            className={Style.link}
            icon="user"
          />

          <Button
            url="/account/password"
            text={"Password "}
            className={Style.link}
            icon="lock"
          />

          <Button
            url="/account/2fa"
            text={"Security 2FA"}
            className={Style.link}
            icon="shield"
          />
          {authContext.permission?.owner && (
            <Button
              url="/account/billing"
              text="billing"
              className={Style.link}
              icon="credit-card"
            />
          )}
          {authContext.permission?.supertyro && (
            <Button
              url="/account/users"
              text={"Users"}
              className={Style.link}
              icon="user"
            />
          )}
          <Button
            text={"Sign Out"}
            className={Style.link}
            icon="log-out"
            action={authContext.signout}
          />

          {authContext.permission?.account_admin && (
            <>
              <Button
                textOnly
                action={closeAccount}
                color="red"
                text="Delete Account"
                icon="alert-octagon"
                className={Style.close}
              />
            </>
          )}
        </AccountCard>
      </Animate>
    </>
  );
}
