import { Account } from "views/account";
import { Profile } from "views/account/profile-tyro";
import { Billing } from "views/account/billing";
import { Settings } from "views/account/settings";
import { Upgrade } from "views/account/upgrade";
import { Users } from "views/account/users-tyro";
import { Password } from "views/account/password";
import { TwoFA } from "views/account/2fa";
import { District } from "views/account/organization/district";
import { School } from "views/account/organization/school";
import { Classroom } from "views/account/organization/classroom";
import { Teacher } from "views/account/organization/teacher";
import { TeacherSchool } from "views/account/organization/teacher-school";
import { Student } from "views/account/organization/student";
import { History } from "views/account/organization/history";
import { Manage } from "views/account/manage";
import { Content } from "views/account/organization/content";
import { Words } from "views/account/organization/words";

const Routes = [
  {
    path: "/account",
    view: Account,
    layout: "app",
    permission: "user",
    title: "Your Account",
  },
  {
    path: "/account/profile",
    view: Profile,
    layout: "app",
    permission: "user",
    title: "Your Profile",
  },
  {
    path: "/account/password",
    view: Password,
    layout: "app",
    permission: "user",
    title: "Change Password",
  },
  {
    path: "/account/2fa",
    view: TwoFA,
    layout: "app",
    permission: "user",
    title: "Manage 2FA",
  },
  {
    path: "/account/billing",
    view: Billing,
    layout: "app",
    permission: "owner",
    title: "Billing",
  },
  {
    path: "/account/settings",
    view: Settings,
    layout: "app",
    permission: "account_admin",
    title: "Settings",
  },
  {
    path: "/account/upgrade",
    view: Upgrade,
    layout: "app",
    permission: "owner",
    title: "Upgrade",
  },
  {
    path: "/account/users",
    view: Users,
    layout: "app",
    permission: "account_admin",
    title: "Users",
  },

  {
    path: "/account/organization/district",
    view: District,
    layout: "app",
    permission: "district_admin",
    title: "District",
  },
  {
    path: "/account/organization/school/:id",
    view: School,
    layout: "app",
    permission: "school_admin",
    title: "School",
  },
  {
    path: "/account/organization/school/:school_id/classroom/:class_id",
    view: Classroom,
    layout: "app",
    permission: "teacher",
    title: "Classroom",
  },
  {
    path: "/account/organization/teacher/school",
    view: TeacherSchool,
    layout: "app",
    permission: "teacher",
    title: "Teacher School",
  },

  {
    path: "/account/organization/teacher/:id",
    view: Teacher,
    layout: "app",
    permission: "teacher",
    title: "Teacher",
  },
  {
    path: "/account/organization/student/:id",
    view: Student,
    layout: "app",
    permission: "teacher",
    title: "Student",
  },

  {
    path: "/account/organization/history/:id",
    view: History,
    layout: "app",
    permission: "teacher",
    title: "History",
  },

  {
    path: "/account/manage",
    view: Manage,
    layout: "app",
    permission: "account_admin",
    title: "School Management",
  },
  {
    path: "/account/organization/content/district",
    view: Content,
    layout: "app",
    permission: "district_admin",
    title: "Content Management",
  },

  {
    path: "/account/organization/content/school/:id",
    view: Content,
    layout: "app",
    permission: "school_admin",
    title: "Content Management",
  },
  {
    path: "/account/organization/words/district",
    view: Words,
    layout: "app",
    permission: "district_admin",
    title: "Word Management",
  },
  {
    path: "/account/organization/words/school/:id",
    view: Words,
    layout: "app",
    permission: "school_admin",
    title: "Word Management",
  },
];

export default Routes;
