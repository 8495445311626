/***
 *
 *   Thumbnail component
 *   Wrapper component to add a thumbnail to an assistant
 *
 *
 **********/

import { useState, useEffect, useContext } from "react";
import { Form, Button, useAPI, ViewContext, ClassHelper } from "components/lib";
import axios from "axios";

import Style from "./starterComponent.module.scss";

export function StarterComponent(props) {
  const viewContext = useContext(ViewContext);

  // import the styles
  const componentStyle = ClassHelper(Style, {
    [props.color]: true,
    className: props.className,
  });

  //set state for the image response
  const [loading, setLoading] = useState(true);
  const [starters, setStarters] = useState([]);
  const [componentId, setComponentId] = useState(props.component_id || null);

  const componentData = useAPI(
    props.parent_id ? `/api/component/all/starter/${props.parent_id}` : null
  );

  console.log("props.component_id ", props.component_id);
  // set the states based on the component data
  useEffect(() => {
    if (componentData.data && !componentData.loading) {
      setStarters(componentData.data.data);
      setComponentId(componentData.data.id.component_id);
    } else if (componentData === null) {
      setLoading(false);
    }
  }, [componentData]);

  async function onRemoveComponent() {
    if (props.parent_id) {
      console.log("componentId", componentId);
      await axios.delete(`/api/component/starter/${componentId}`);
    }
    viewContext.snack.show("Starters removed", "success");
    props.onRemove();
  }

  function deleteAStarter(data) {
    viewContext.modal.show(
      {
        title: "Delete Starter",
        form: {},
        buttonText: "Delete Starter",
        text: `Are you sure you want to delete ${data.title}?`,
        url: `/api/starter/${data.id}`,
        method: "DELETE",
        destructive: true,
      },
      () => {
        viewContext.notification.show(
          data.title + " was deleted",
          "success",
          true
        );
        // Remove the deleted starter from the state
        setStarters(starters.filter((starter) => starter.id !== data.id));
      }
    );
  }

  function editStarter(data) {
    viewContext.modal.show(
      {
        title: "Update Starter",
        form: {
          id: {
            type: "hidden",
            value: data.id,
          },
          title: {
            label: "Title",
            type: "text",
            value: data.title,
            required: true,
          },
          description: {
            label: "Description",
            type: "textarea",
            value: data.description,
            required: true,
          },
        },
        buttonText: "Save",
        url: "/api/starter",
        method: "PATCH",
      },
      (res) => {
        viewContext.notification.show(
          res.title.value + " was updated",
          "success",
          true
        );

        // Update the starter in the state
        setStarters(
          starters.map((starter) => {
            if (starter.id === res.id.value) {
              return {
                id: res.id.value,
                title: res.title.value,
                description: res.description.value,
              };
            } else {
              return starter;
            }
          })
        );
      }
    );
  }

  function addStarter(parent_id) {
    viewContext.modal.show(
      {
        title: "Add Starter",
        form: {
          parent_id: {
            type: "hidden",
            value: parent_id,
          },
          title: {
            label: "Title",
            type: "text",
            required: true,
          },
          description: {
            label: "Description",
            type: "textarea",
            required: true,
          },
        },
        buttonText: "Add Starter",
        url: "/api/component/starterComponent",
        method: "PATCH",
      },
      (form, res) => {
        console.log("addStarterCallback: ", res);

        setStarters((prevStarters) => [...prevStarters, res.starter]);
        setComponentId(res.id);
        viewContext.snack.show("Starter added", "success");
      }
    );
  }

  return (
    <div className={componentStyle}>
      <Button
        text="New"
        small
        green
        fullWidth
        className={Style.new}
        action={() => addStarter(props.parent_id)}
        rounded
      />
      <br />

      <div>
        {starters?.map((starter, index) => {
          return (
            <div
              key={index}
              className={Style.starter}
              onClick={() => editStarter(starter)}
            >
              <div>
                <strong>{starter.title}</strong> <br />
                {starter.description.length > 20
                  ? starter.description.substring(0, 20) + "..."
                  : starter.description}
              </div>
              <Button
                icon="trash"
                className={Style.edit}
                action={() => deleteAStarter(starter)}
              />
            </div>
          );
        })}

        {props.onRemove && (
          <button onClick={onRemoveComponent} className="removeBtn">
            Remove Component
          </button>
        )}
      </div>
    </div>
  );
}
