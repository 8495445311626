/***
 *
 *   Thumbnail component
 *   Wrapper component to add a thumbnail to an assistant
 *
 *
 **********/

import { useState, useEffect, useContext } from "react";
import { Button, Icon, ViewContext, ClassHelper } from "components/lib";
import axios from "axios";

import Style from "./fileComponent.module.scss";

export function FileComponent(props) {
  const componentStyle = ClassHelper(Style, {
    [props.color]: true,
    className: props.className,
  });

  const viewContext = useContext(ViewContext);
  //set state for the response
  const [loading, setLoading] = useState(true);
  const [files, setFiles] = useState([]);
  const [componentId, setComponentId] = useState(props.component_id || null);

  // LOGIC
  // if there is a component id then we will get the component data
  // this will include all the files that are associated with the component
  // if there is not a component id then we will create a new component

  // set the states based on the component data
  useEffect(() => {
    const fetchData = async () => {
      if (componentId && files.length === 0) {
        //  load the component data
        let res = await axios.get(`/api/component/all/file/${componentId}`);
        setFiles(res.data.files);
        setLoading(false);
      } else {
        // no id, then this is a new component
        let res = await axios.post("/api/component/file/", {
          parent_id: props.parent_id,
        });
        setComponentId(res.data.component_id);
      }
    };
    fetchData();
  }, [componentId]);

  async function onRemoveComponent() {
    if (componentId) {
      await axios.delete(`/api/component/file/${componentId}`);
    }
    viewContext.snack.show("File component removed", "success");
    props.onRemove();
  }

  function deleteAFile(data) {
    viewContext.modal.show(
      {
        title: "Delete File",
        form: {},
        buttonText: "Delete File",
        text: `Are you sure you want to delete ${data.name}?`,
        url: `/api/file/${data.id}`,
        method: "DELETE",
        destructive: true,
      },
      () => {
        viewContext.notification.show(
          data.name + " was deleted",
          "success",
          true
        );
        // Remove the deleted starter from the state
        setFiles(files.filter((file) => file.id !== data.id));
      }
    );
  }

  function addFile() {
    viewContext.modal.show(
      {
        title: "Add File",
        form: {
          parent_id: {
            type: "hidden",
            value: props.parent_id,
          },
          parent_type: {
            type: "hidden",
            value: props.parent_type,
          },
          component_id: {
            type: "hidden",
            value: componentId,
          },
          name: {
            label: "Name",
            type: "text",
            required: true,
          },
          file: {
            label: "Upload a file",
            type: "file",
            required: true,
          },
        },
        buttonText: "Add File",
        url: "/api/file/new",
        method: "POST",
      },
      (form, res) => {
        setFiles((prevFiles) => [...prevFiles, res]);
        setComponentId(res.id);
        viewContext.snack.show("File added", "success");
      }
    );
  }

  // we could use the same function for adding and editing a file
  // currently different api end points are used for adding and editing
  function editFile(data) {
    viewContext.modal.show(
      {
        title: "Update File",
        form: {
          id: {
            type: "hidden",
            value: data.id,
          },
          parent_id: {
            type: "hidden",
            value: props.parent_id,
          },
          parent_type: {
            type: "hidden",
            value: props.parent_type,
          },
          component_id: {
            type: "hidden",
            value: componentId,
          },
          name: {
            label: "Name",
            type: "text",
            value: data.name,
            required: true,
          },
          file: {
            label: "Upload a file",
            type: "file",
          },
        },
        buttonText: "Save",
        url: "/api/file/edit",
        method: "PATCH",
      },
      (res) => {
        viewContext.notification.show(
          res.name.value + " was updated",
          "success",
          true
        );

        // Update the starter in the state
        setFiles(
          files.map((file) => {
            if (file.id === res.id.value) {
              return {
                id: res.id.value,
                name: res.name.value,
              };
            } else {
              return file;
            }
          })
        );
      }
    );
  }

  return (
    <div className={componentStyle}>
      <Button
        text="New"
        small
        green
        fullWidth
        className={Style.new}
        action={() => addFile()}
        rounded
      />
      <br />

      <div>
        {files?.map((file, index) => {
          return (
            <div
              key={file.name}
              className={Style.files}
              onClick={() => editFile(file)}
            >
              <Icon image="file" size="28" />
              <div>
                {file.name}
                <span className={Style.subtext}>{file.file_name}</span>
              </div>
              <Button
                icon="trash"
                className={Style.edit}
                action={() => deleteAFile(file)}
              />
            </div>
          );
        })}

        {props.onRemove && (
          <button onClick={onRemoveComponent} className="removeBtn">
            Remove Component
          </button>
        )}
      </div>
    </div>
  );
}
