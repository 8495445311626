/***
 *
 *   CARD
 *   Universal container for grouping UI components together
 *
 *
 **********/

import { Loader, ClassHelper, Button } from "components/lib";
import Style from "./featuredItemCard.module.scss";
import { useNavigate } from "react-router-dom";

export function FeaturedItemCard(props) {
  const navigate = useNavigate();

  const cardStyle = ClassHelper(Style, {
    loading: props.loading,
    className: props.className,
    aiType: props.aiType,
  });

  // create to the correct edit URL
  const path = props.aiType === "workspace" ? "space" : "assistant";

  return (
    <section
      className={`${cardStyle} ${Style[props.aiType]}`}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();

        props.goto && navigate(props.goto);
        props.onClick && props.onClick();
      }}
    >
      <div className={Style.thumbnail}>
        <img src={props.data.thumbnail_url} />
      </div>
      <div className={Style.content}>
        <h3 className={Style.title}>{props.data.title}</h3>
        {props.aiType === "workspace" ? (
          <p>{props.data.description}</p>
        ) : null}{" "}
        {props.buttonText && (
          <Button
            text={props.buttonText}
            icon="loader"
            action={(e) => {
              props.goto && navigate(props.goto);
              props.onClick && props.onClick();
            }}
          />
        )}
      </div>

      {props.loading ? <Loader /> : props.children}
    </section>
  );
}
