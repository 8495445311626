/***
 *
 *   HOME LAYOUT
 *   Main website layout
 *
 **********/

import { Fragment, useContext } from "react";
import { HomeNav, AuthContext, Footer } from "components/lib";

export function HomeLayout(props) {
  const context = useContext(AuthContext);

  return (
    <Fragment>
      <HomeNav />
      <main className="home">{<props.children {...props.data} />}</main>
      <Footer />
    </Fragment>
  );
}
