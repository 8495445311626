import { Icon, ClassHelper } from "components/lib";
import { useContext } from "react";
import { AuthContext } from "components/lib";

import Style from "./chatMessage.module.scss";
import Markdown from "react-markdown";

export function ChatMessage(props) {
  // allows us to get props/functions from parent
  const authContext = useContext(AuthContext);

  const cardStyle = ClassHelper(Style, {
    loading: props.loading,
    className: props.className,
  });
  return (
    <div className={cardStyle}>
      <div className={Style.chatMessageText}>
        {props.role === "user" ? (
          <div className={Style.userIcon}>
            {authContext.user.name.charAt(0)}
          </div>
        ) : (
          <div className={Style.aiIcon}>Ai</div>
        )}
        <div className={props.role === "user" ? Style[props.role] : ""}>
          <Markdown>{props.text}</Markdown>
        </div>
      </div>
    </div>
  );
}
