/***
 *
 *   Classroom Page
 *   view a classroom
 *
 **********/

import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import {
  OrganizationCard,
  Header,
  Animate,
  OrganizationTable,
  useAPI,
} from "components/lib";

export function Classroom(props) {
  const params = useParams();

  console.log("params", params);

  //get classroom data
  const classroom =
    useAPI(
      "/api/enrollments/school/" +
        params.school_id +
        "/classroom/" +
        params.class_id
    ) || [];

  // get teachers
  const teachers =
    useAPI(
      "/api/enrollments/school/" +
        params.school_id +
        "/classroom/" +
        params.class_id +
        "/teachers"
    ) || [];

  //get students
  const students =
    useAPI(
      "/api/enrollments/school/" +
        params.school_id +
        "/classroom/" +
        params.class_id +
        "/students"
    ) || [];

  return (
    <>
      <Header />

      <header className="content-header">
        <h1>
          Classroom: <span>{classroom.data?.name}</span>
        </h1>
      </header>

      <Animate>
        <OrganizationCard loading={classroom.loading} title={"About"}>
          <b>Subject:</b> {classroom.data?.subject}
          <br />
          <b>Periods:</b> {classroom.data?.periods}
        </OrganizationCard>

        <OrganizationCard
          loading={teachers.loading}
          title={"Teachers " + teachers.data?.length}
        >
          <OrganizationTable
            loading={teachers?.loading}
            data={teachers?.data}
            show={["name"]}
            link={{
              url: "/account/organization/teacher/",
              id: "id",
            }}
          />
        </OrganizationCard>

        <OrganizationCard title={"Students " + students?.data?.length}>
          <OrganizationTable
            search
            loading={students?.loading}
            data={students?.data}
            show={["name"]}
            link={{
              url: "/account/organization/student/",
              id: "id",
            }}
          />
        </OrganizationCard>
      </Animate>
    </>
  );
}
