import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";

import {
  ChatroomCard,
  Animate,
  Grid,
  Feedback,
  AuthContext,
  Search,
  useAPI,
} from "components/lib";

let favorites = [];

export function Spaces(props) {
  //allows us to get infomration about the user
  // in this case we want to find if the user is part of the
  //tyro account and show tyro prompts to them
  const authContext = useContext(AuthContext);
  // used to update the URL
  const navigate = useNavigate();

  const params = useParams();

  // determine what type of list we are using
  const listType = props.listType;
  let path = `/api/ai/spaces/${listType}`;
  if (listType == "collection") path += `/${params.collection_id}`;

  const spaces = useAPI(path);

  //state
  const [spacesFiltered, setSpacesFiltered] = useState([]);

  useEffect(() => {
    if (spaces.data && !spaces.loading) {
      setSpacesFiltered(spaces);
    }
  }, [spaces]);

  function search(term) {
    // search  descriptiona and title in each prompt
    // update state to show only filtered prompts

    let searchResults = [];

    spaces?.data?.forEach((space) => {
      if (
        space.description
          .toString()
          .toLowerCase()
          .includes(term.toLowerCase()) ||
        space.title.toString().toLowerCase().includes(term.toLowerCase())
      ) {
        if (!searchResults.includes(space)) searchResults.push(space);
      }
    });
    setSpacesFiltered({ data: searchResults });
  }

  return (
    <>
      <header className="content-topic promptHeader">
        <Search callback={search} placeholder={`Search`} />

        {listType == "collection" ? (
          <h1>Collection{spacesFiltered?.data?.title}</h1>
        ) : null}
      </header>

      <Animate timeout={500}>
        {spacesFiltered?.data?.length < 1
          ? "Sorry, there are no results matching your search."
          : null}
        <div className="spaceList">
          <Grid cols="4">
            {spacesFiltered.data?.map((space) => {
              return (
                <ChatroomCard
                  key={space.id}
                  chat={space}
                  edit={authContext.permission?.supertyro}
                  type={space.type}
                  className={space.active ? "active" : "notActive"}
                ></ChatroomCard>
              );
            })}
          </Grid>
        </div>
      </Animate>
      <div id="snackbar"> </div>

      <Feedback />
    </>
  );
}
