/***
 *
 *   Prayer BUTTON
 *   Can be a standard button, icon button or with loading animation
 *
 *   PROPS
 *   text: button label
 *   action: callback function executed on click
 *
 *
 **********/

import { Icon, ClassHelper, useNavigate } from "components/lib";
import { useState } from "react";

import Style from "./prayerButton.module.scss";

export function PrayerButton(props) {
  const navigate = useNavigate();

  const colors = [
    "color1",
    "color2",
    "color3",
    "color4",
    "color5",
    "color6",
    "color7",
    "color8",
    "color9",
    "color10",
  ];

  const cardStyle = ClassHelper(Style, {
    loading: props.loading,
    className: props.className,
    dark: props.dark,
  });

  const [color, setColor] = useState(() => {
    const randomIndex = Math.floor(Math.random() * colors.length);
    return colors[randomIndex];
  });

  return (
    <div
      className={`${cardStyle} ${Style[color]}`}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();

        if (props.onClick) {
          props.onClick();
        } else {
          navigate(`/ai/space/${props.space_id}/starter/${props.starter_id}`);
        }
      }}
    >
      <h4>{props.title}</h4>
      <p>{props.description}</p>
    </div>
  );
}
