import {
  Row,
  Button,
  OrganizationCard,
  Grid,
  Table,
  useAPI,
} from "components/lib";
import Style from "./ai_creator.view.scss";

export function Create() {
  const assistants = useAPI("/api/ai/assistant");
  const spaces = useAPI("/api/ai/spaces");

  return (
    <Row
      title="Create"
      desc="Select the type of Ai you would like to create"
      color="white"
    >
      <div className="Flex2">
        <OrganizationCard
          title="Create a new space"
          className={Style.createCard}
        >
          <p> Create a new workspace or chatroom.</p>
          <Button
            url="/ai/create/space"
            text="Space"
            className="inline-block"
          />
        </OrganizationCard>

        <OrganizationCard
          title="Create a new assistant"
          className={Style.createCard}
        >
          <p> Create a new assistant to use in your spaces.</p>
          <Button
            url="/ai/create/assistant"
            text="Assistant"
            className="inline-block"
          />
        </OrganizationCard>
      </div>
      <OrganizationCard title="Spaces">
        <Table
          data={spaces.data}
          loading={spaces.loading}
          show={["title", "type", "active"]}
          badge={{
            col: "active",
            color: "green",
            condition: [
              { value: 1, color: "green" },
              { value: 0, color: "red" },
            ],
          }}
          actions={{
            view: {
              url: "/ai/create/space",
              col: "id",
            },
          }}
        />
      </OrganizationCard>
      <OrganizationCard title="Assistants">
        <Table
          data={assistants.data}
          loading={assistants.loading}
          show={["title", "active", "type"]}
          badge={{
            col: "active",
            color: "green",
            condition: [
              { value: 1, color: "green" },
              { value: 0, color: "red" },
            ],
          }}
          actions={{
            view: {
              url: "/ai/create/assistant",
              col: "id",
            },
          }}
        />
      </OrganizationCard>
    </Row>
  );
}
