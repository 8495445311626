/***
 *
 *   FULLSCREEN MODAL
 *   Display an overlay modal
 *
 *
 **********/

import { useState } from "react";

import { Card, Loader, Button, ClassHelper, ViewContext } from "components/lib";
import { CSSTransition } from "react-transition-group";
import Style from "./fullscreen.module.scss";
import { useContext } from "react";

export function FullScreen(props) {
  const style = ClassHelper(Style, {
    className: props.className,
    large: props.large,
    medium: props.medium,
    textCenter: props.textCenter,
  });

  const context = useContext(ViewContext);

  return (
    <CSSTransition in appear timeout={0} className={style}>
      <div className={Style} onClick={props.closeFunction}>
        <div>
          <Card title={props.title} className={Style.fullscreenContent}>
            {props.closeBtn && (
              <Button
                action={props.closeFunction}
                icon="x"
                className={Style.close}
              />
            )}
            {props.text && <p>{props.text}</p>}
            {props.loading ? <Loader /> : props.children}
          </Card>
        </div>
      </div>
    </CSSTransition>
  );
}
