/***
 *
 *   CREATE AN ASSISTANT
 *
 **********/

import React, { useState, useEffect, useContext } from "react";
import Axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import {
  Grid,
  ExpandableCard,
  useAPI,
  Form,
  OrganizationCard,
  Button,
  ViewContext,
  Header,
  ThumbnailComponent,
  MetadataComponent,
  FileComponent,
  StarterComponent,
} from "components/lib";

// css styles
import Style from "./ai_creator.view.scss";

///prop function
export function CreateSpace(props) {
  const context = useContext(ViewContext);

  // for the redirect after form submission
  const navigate = useNavigate();

  //get the params
  const [params, setParams] = useState(useParams());

  // form state
  const [formData, setFormData] = useState({});
  const [spaceType, setSpaceType] = useState();

  //store if the form is in edit mode
  const [isEditMode, setEditMode] = useState(!!params.space_id);

  //get the list of assistants
  const assistants = useAPI("/api/ai/assistant/min");
  // filter the assistants by type

  //get the list of collections
  const collections = useAPI("/api/ai/collections/min");

  //post the form data to the api depending on if it is a new prompt or an edit
  const postUrl = isEditMode
    ? `/api/ai/space/${params.space_id}`
    : "/api/ai/space";

  useEffect(() => {
    if (params.space_id) {
      Axios.get(`/api/ai/spacefull/${params.space_id}`)
        .then((response) => {
          const data = response.data.data;
          if (data) {
            setSpaceType(data.type);
            setFormData(data);
          }
          if (data.components) {
            addComponentFromResponse(data.components);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [params]);

  function assistantCallback(res) {
    setFormData({ ...formData, ...res.data.data });
    context.snack.show(`Space ${isEditMode ? "updated" : "created"}`);

    if (!isEditMode) {
      setEditMode(true);
      navigate(`/ai/create/space/${res.data.data.id}`);
    }
  }

  async function deleteAssistant(id) {
    await Axios({
      method: "delete",
      url: `/api/ai/space/${id}`,
      params: {
        id: id,
      },
    })
      .then((response) => {
        context.snack.show("space deleted");
        setTimeout(function () {
          navigate("/ai/create");
        }, 1000);
      })
      .catch((error) => {
        context.snack.show("error deleting space");
      });
  }

  //COMPONENT MANAGEMENT
  const [currentComponents, setCurrentComponents] = useState([]);

  function addComponentFromResponse(components) {
    console.log("components", components);
    // const componentTypes = components.map(  (component) => component.component_type );
    setCurrentComponents(components);
  }

  const addComponent = (component) => {
    if (
      !currentComponents.some(
        (currentComponent) =>
          currentComponent.component_type === component.component_type
      )
    ) {
      setCurrentComponents((prevComponents) => [...prevComponents, component]);
    }
  };

  const removeComponent = (component) => {
    if (currentComponents.includes(component)) {
      setCurrentComponents((prevComponents) =>
        prevComponents.filter((comp) => comp !== component)
      );
      context.snack.show(friendlyName(component.component_type) + " removed");
    }
  };

  const renderComponent = (component) => {
    switch (component.component_type) {
      case "metadata":
        return (
          <MetadataComponent
            parent_id={params.space_id}
            component_id={component.component_id}
            parent_type="space"
            onRemove={() => removeComponent(component)}
          />
        );
      case "thumbnail":
        return (
          <ThumbnailComponent
            parent_id={params.space_id}
            component_id={component.component_id}
            parent_type="space"
            onRemove={() => removeComponent(component)}
          />
        );
      case "file":
        return (
          <FileComponent
            parent_id={params.space_id}
            component_id={component.component_id}
            parent_type="space"
            onRemove={() => removeComponent(component)}
          />
        );
      case "starter":
        return (
          <StarterComponent
            parent_id={params.space_id}
            component_id={component.component_id}
            parent_type="space"
            onRemove={() => removeComponent(component)}
          />
        );

      default:
        return null;
    }
  };

  const friendlyName = (name) => {
    return name.replace(/\b\w/g, (l) => l.toUpperCase()).replace("Ai", "AI");
  };

  function spaceChangeHandler(e) {
    if (e.value == "workspace" || e.value == "chatroom") {
      setSpaceType(e.value);
    }
  }

  return (
    <div className={Style.ai_creator}>
      <Header>
        {isEditMode ? (
          <button
            className="red-btn delete-btn"
            onClick={() => deleteAssistant(params.space_id)}
          >
            DELETE
          </button>
        ) : null}
      </Header>
      <Grid cols={2}>
        <OrganizationCard title="Ai Creator">
          <ExpandableCard
            title={`Space`}
            expanded={formData?.components?.length === 0}
          >
            {!assistants.loading && assistants && (
              <Form
                key={spaceType}
                url={postUrl}
                method={isEditMode ? "patch" : "post"}
                buttonText="Save"
                updateOnChange={true}
                onChange={(e) => {
                  spaceChangeHandler(e);
                }}
                callback={(res) => {
                  assistantCallback(res);
                }}
                inputs={{
                  active: {
                    label: "Publish",
                    type: "switch",
                    default: formData.active || false,
                  },
                  type: {
                    label: "space type",
                    type: "select",
                    default: spaceType || "",
                    options: [
                      { value: "workspace", label: "Workspace" },
                      { value: "chatroom", label: "Chatroom" },
                    ],

                    required: true,
                  },
                  assistant_id: {
                    label: "Assistant",
                    type: "select",
                    default: formData.assistant_id || "",
                    options: assistants.data?.map((assistant) => ({
                      value: assistant.id,
                      label: assistant.type + " - " + assistant.title,
                    })),
                    required: true,
                  },

                  title: {
                    label: "Title",
                    type: spaceType == "workspace" ? "text" : "hidden",
                    required: spaceType == "workspace" ? true : false,
                    value: formData.title,
                    placeholder: "Give your space a title",
                    errorMessage: "Please give the space a title",
                  },
                  description: {
                    label: "Description",
                    type: spaceType == "workspace" ? "textarea" : "hidden",
                    required: spaceType == "workspace" ? true : false,
                    value: formData.description || "",
                    placeholder: "Give your space a description",
                    errorMessage: "Please give the space a description",
                  },
                  greeting: {
                    label: "Greeting",
                    type: spaceType == "workspace" ? "text" : "hidden",
                    required: spaceType == "workspace" ? true : false,
                    value: formData.greeting || "",
                    placeholder: "Give your space a greeting",
                    errorMessage: "Please give the space a greeting",
                  },

                  instructions: {
                    label: "Instructions",
                    type: spaceType == "workspace" ? "textarea" : "hidden",
                    required: spaceType == "workspace" ? true : false,
                    value: formData.instructions || "",
                    placeholder: "Instructions for your space to follow",
                    errorMessage: "Please give the space instructions",
                  },

                  collection_id: {
                    label: "Collection",
                    type: "select",
                    default: formData.collection_id || "",
                    options: [
                      {
                        value: "",
                        label: "None",
                      },
                      ...(collections.data?.map((collection) => ({
                        value: collection.id,
                        label: collection.title,
                      })) || []),
                    ],
                  },

                  featured: {
                    label: "Featured",
                    type: "switch",
                    default: formData.featured || false,
                  },
                  allow_uploads: {
                    label: "Allow File Uploads",
                    type: "switch",
                    default: formData.allow_uploads || false,
                  },
                }}
              />
            )}
          </ExpandableCard>

          {/* Area to load components */}
          <div>
            {currentComponents.map((component, index) => (
              <div key={index}>
                <ExpandableCard title={friendlyName(component.component_type)}>
                  {renderComponent(component)}
                </ExpandableCard>
              </div>
            ))}
          </div>
        </OrganizationCard>
        <OrganizationCard title="Components">
          {isEditMode ? (
            <>
              <Button
                text="Thumbnail"
                icon="plus"
                color="#d95565"
                size={13}
                action={() => addComponent({ component_type: "thumbnail" })}
              />

              <Button
                text="Files"
                icon="plus"
                color="#d95565"
                size={13}
                action={() => addComponent({ component_type: "file" })}
              />

              <Button
                text="Starters"
                icon="plus"
                color="#d95565"
                size={13}
                action={() => addComponent({ component_type: "starter" })}
              />
            </>
          ) : null}
        </OrganizationCard>
      </Grid>

      <div id="snackbar"> </div>
    </div>
  );
}
