/***
 *
 *   Advanced Chat component
 *   Wrapper component to add advanced elements to a chat prompt
 *
 *
 **********/

import { useState, useEffect, useContext } from "react";
import { Form, useAPI, ViewContext } from "components/lib";
import axios from "axios";

export function MetadataComponent(props) {
  const context = useContext(ViewContext);
  const componentData = useAPI(
    props.id ? `/api/ai/component/metadata/${props.id}` : null
  );

  async function onRemove() {
    console.log("Goodbye", props.id);
    if (props.id) {
      await axios.delete(`/api/ai/component/memory/${props.id}`);
      context.snack.show("Memory deleted", "success");
    }
    props.onRemove();
  }

  return (
    <>
      {!componentData.loading && (
        <Form
          inputs={{
            prompt_id: {
              type: "hidden",
              value: props.id,
            },
          }}
          method="PATCH"
          url="/api/prompt/component/chat_advanced_params"
          buttonText="Save"
          callback={() => context.snack.show("Advanced chat params updated")}
        />
      )}

      {props.onRemove && (
        <button onClick={onRemove} className="removeBtn">
          Remove
        </button>
      )}
    </>
  );
}
