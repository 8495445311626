/***
 *
 *   SCHOOL Page
 *   view the school data including classrooms
 *   depending on permisisons show all classes (admin) or teacher classes
 *
 **********/

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Axios from "axios";

import {
  OrganizationCard,
  Header,
  Grid,
  OrganizationTable,
  useAPI,
} from "components/lib";

export function School(props) {
  const params = useParams();
  const [classes, setClasses] = useState([{ loading: true }]);

  // get school
  const school = useAPI("/api/account/school/" + params.id);

  console.log("school", school);

  useEffect(() => {
    if (!school.loading && school.data?.source_id) {
      Axios.get("/api/account/school/" + school.data.source_id + "/classes")
        .then((response) => {
          setClasses(response.data);
          console.log("classes " + response.data);
        })
        .catch((error) => {
          console.log("error ", error);
        });
    }
  }, [school]);

  const classesList = ["name", "subject", "periods", "teacher_name"];

  return (
    <>
      <Header />

      <header className="content-header">
        <h1>
          School: <span>{school.data?.name}</span>
        </h1>
      </header>

      <OrganizationCard loading={school.loading} title={"About"}>
        <Grid cols={2}>
          {!school.data?.contact_title ? null : (
            <div>
              <b>Primary Contact:</b>
              <br />
              Name: {school.data?.contact_name}
              <br />
              Title:{" "}
              {(school.data?.contact_title).charAt(0).toUpperCase() +
                school.data?.contact_title.slice(1)}
              <br />
              Email: {school.data?.contact_email}
              <br />
            </div>
          )}
          {!school.data?.location_state ? null : (
            <div>
              <b>Location:</b>
              <br />
              State: {school.data?.location_state}
              <br />
              City: {school.data?.location_city}
              <br />
            </div>
          )}
        </Grid>
      </OrganizationCard>

      <OrganizationCard title={"Classes " + classes?.data?.length}>
        <OrganizationTable
          search
          loading={classes?.loading}
          data={classes?.data}
          show={classesList}
          hide={["source_id"]}
          link={{
            url: "/account/organization/school/" + school.data?.source_id,
            classroom_id: "source_id",
          }}
        />
      </OrganizationCard>
    </>
  );
}
