export const LogoSVG = (props) => (
  <a href={props.link} className={props.className}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Layer_2"
      viewBox="0 0 257.75 88.65"
      className={"logoSVG"}
    >
      <g id="Layer_4">
        <path
          className="text"
          d="M36.35 60.91H30.4c-7.45 0-7.12-6.24-7.12-9.5V26.9h12.1c.65 0 1.19-.21 1.61-.63.42-.42.63-.96.63-1.61v-3.87c0-.65-.21-1.19-.63-1.61-.42-.42-.96-.63-1.61-.63h-12.1V2.24c0-.65-.21-1.19-.63-1.61-.42-.42-.96-.63-1.61-.63h-4.59c-.65 0-1.19.21-1.61.63-.42.42-.63.96-.63 1.61v16.29H2.24c-.65 0-1.19.21-1.61.63-.42.42-.63.96-.63 1.61v3.87c0 .65.21 1.19.63 1.61.42.42.96.63 1.61.63H14.2v25.2c-.11 13.57 5.14 17.17 15.41 17.17h6.73c.65 0 1.19-.21 1.61-.63.42-.42.63-.96.63-1.61v-3.87c0-.65-.21-1.19-.63-1.61-.42-.42-.96-.63-1.61-.63ZM136.76 18.54h-3.8c-6.44 0-10.99.21-13.66 4.63v-2.29c0-.65-.23-1.2-.68-1.66-.46-.46-1.01-.68-1.66-.68h-4.49c-.65 0-1.19.23-1.61.68-.42.46-.63 1.01-.63 1.66v46.14c0 .65.21 1.19.63 1.61.42.42.96.63 1.61.63h4.78c.65 0 1.2-.21 1.66-.63.45-.42.68-.96.68-1.61V38.34c0-3.51 1.01-6.29 3.02-8.34 2.02-2.05 4.78-3.07 8.29-3.07h5.85c.65 0 1.19-.2 1.61-.59.42-.39.63-.91.63-1.56v-4c0-.65-.21-1.19-.63-1.61-.42-.42-.96-.63-1.61-.63Z"
        />

        <path
          className="bar"
          d="M255.73 60.91h-48.27c-1.12 0-2.02.82-2.02 1.83v4.78c0 1.01.91 1.83 2.02 1.83h48.27c1.12 0 2.02-.82 2.02-1.83v-4.78c0-1.01-.91-1.83-2.02-1.83Z"
        />

        <path
          className="text"
          d="M59.9 88.64c6.2.05 10.56-4.04 12.58-7.98 1.62-3.17 5.76-12.77 5.76-12.77l19.43-44.81c.32-.98.49-1.56.49-1.76 0-.52-.19-.98-.59-1.37-.39-.39-.88-.59-1.46-.59h-4.58c-1.11 0-1.92.55-2.44 1.66L73.87 56.53 59.04 21.02c-.52-1.11-1.3-1.66-2.34-1.66h-4.78c-.59 0-1.07.2-1.46.59-.39.39-.59.88-.59 1.46 0 .26.16.81.49 1.66l18.63 44-.51 1.46s-2.78 7.95-3.89 9.61c-1.1 1.66-2.93 2.14-5.46 2.14h-5.95c-.65 0-1.19.21-1.61.63-.42.42-.63.96-.63 1.61v3.86c0 .65.21 1.19.63 1.61.42.42.96.63 1.61.63h6.73ZM194.27 43.84c0 9.24-1.7 15.78-5.1 19.6-3.4 3.83-9.22 5.74-17.48 5.74s-14.42-1.85-17.72-5.54c-3.3-3.7-4.95-10.3-4.95-19.8s1.7-15.86 5.1-19.65c3.4-3.79 9.26-5.69 17.57-5.69s14.41 1.85 17.67 5.55c3.27 3.7 4.9 10.3 4.9 19.8Zm-35.88-.1c0 6.64.87 11.03 2.62 13.17 1.75 2.14 5.31 3.86 10.68 3.86s8.69-1.78 10.5-4.05c1.81-2.26 2.71-6.59 2.71-12.99s-.86-11.03-2.58-13.17c-1.72-2.14-5.26-3.64-10.63-3.64s-8.78 1.54-10.59 3.77c-1.81 2.23-2.71 6.58-2.71 13.03Z"
        />
      </g>
    </svg>
  </a>
);
