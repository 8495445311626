export const LogoIconSVG = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 52.32 87.24"
    className={props.className}
  >
    <path
      className="bar"
      d="M50.3 79.16H2.02C.9 79.16 0 79.95 0 80.91v4.57c0 .97.91 1.75 2.02 1.75h48.27c1.12 0 2.02-.79 2.02-1.75v-4.57c0-.97-.91-1.75-2.02-1.75Z"
    />

    <path
      className="t"
      d="M43.21 60.91h-5.95c-7.45 0-7.12-6.24-7.12-9.5V26.9h12.1c.65 0 1.19-.21 1.61-.63.42-.42.63-.96.63-1.61v-3.87c0-.65-.21-1.19-.63-1.61-.42-.42-.96-.63-1.61-.63h-12.1V2.24c0-.65-.21-1.19-.63-1.61C29.09.21 28.55 0 27.9 0h-4.59c-.65 0-1.19.21-1.61.63-.42.42-.63.96-.63 1.61v16.29H9.11c-.65 0-1.19.21-1.61.63-.42.42-.63.96-.63 1.61v3.87c0 .65.21 1.19.63 1.61.42.42.96.63 1.61.63h11.96v25.2c-.11 13.57 5.14 17.17 15.41 17.17h6.73c.65 0 1.19-.21 1.61-.63.42-.42.63-.96.63-1.61v-3.87c0-.65-.21-1.19-.63-1.61-.42-.42-.96-.63-1.61-.63Z"
    />
  </svg>
);
