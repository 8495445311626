import { useEffect, useRef, useState } from "react";

export const useOnInView = () => {
  const [elements, setElements] = useState([]);
  const [entries, setEntries] = useState([]);

  const observer = useRef(null);

  useEffect(() => {
    observer.current = new IntersectionObserver(
      (observedEntries) => {
        setEntries(observedEntries);
      },
      {
        threshold: 0.5,
      }
    );

    elements.forEach((element) => {
      if (element) observer.current.observe(element);
    });

    return () => {
      if (observer.current) {
        observer.current.disconnect();
      }
    };
  }, [elements]);

  useEffect(() => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        // Element comes into view
        entry.target.classList.add("inView");
      } else {
        // Element goes out of view
        entry.target.classList.remove("inView");
      }
    });
  }, [entries]);

  return [setElements, entries];
};
