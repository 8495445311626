/***
 *
 *   HOME LAYOUT
 *   Main website layout
 *
 **********/

import { HomeNav, Logo } from "components/lib";

export function DocsLayout(props) {
  return (
    <>
      <HomeNav />
      <main className="docs">{<props.children {...props.data} />}</main>
    </>
  );
}
