/***
 *
 *   CARD
 *   Universal container for grouping UI components together
 *
 *
 **********/

import { Loader, ClassHelper, Button } from "components/lib";
import Style from "./collectionCard.module.scss";
import { useNavigate } from "react-router-dom";

export function CollectionCard(props) {
  const navigate = useNavigate();

  const cardStyle = ClassHelper(Style, {
    loading: props.loading,
    className: props.className,
  });

  return (
    <section
      className={cardStyle}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        navigate(`/ai/list/topics/collection/${props.id}`);
      }}
    >
      {props.loading ? <Loader /> : props.children}
      <div className={Style.images}>
        {props.spaces?.map((space, index) => {
          return (
            <img
              key={space.id}
              src={space.thumbnail_url}
              className={`img${index + 1}`}
            />
          );
        })}
      </div>
      <h3>{props.title}</h3>
      <p>{props.description}</p>
    </section>
  );
}
