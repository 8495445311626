/***
 *
 *   Thumbnail component
 *   Wrapper component to add a thumbnail to an assistant
 *
 *
 **********/

import { useState, useEffect, useContext } from "react";
import { Form, Switch, useAPI, ViewContext } from "components/lib";
import axios from "axios";

export function ThumbnailComponent(props) {
  const context = useContext(ViewContext);
  //set state for the image response
  const [thumbnail, setThumbnail] = useState({});
  const [loading, setLoading] = useState(true);
  const [isAiGenerated, setIsAiGenerated] = useState(false);

  const componentData = useAPI(
    props.parent_id ? `/api/component/thumbnail/${props.parent_id}` : null
  );

  function thumbnailCallback(res) {
    setThumbnail({ url: res.data.url, prompt: res.data.text });
  }

  async function onRemove() {
    if (props.parent_id && componentData?.data?.id) {
      await axios.delete(`/api/component/thumbnail/${componentData.data.id}`);
    }
    context.snack.show("Thumbnail removed", "success");
    props.onRemove();
  }

  useEffect(() => {
    if (componentData.data && !componentData.loading) {
      setThumbnail({
        url: componentData?.data?.thumbnail_url,
        prompt: componentData?.data?.thumbnail_prompt,
      });
      setIsAiGenerated(componentData?.data?.ai_generated);
    } else if (componentData === null) {
      setLoading(false);
    }
  }, [componentData]); // empty dependency array causes the effect to only run on mount and clean up on unmount

  return (
    <div>
      <Switch
        label="AI Generated"
        default={isAiGenerated}
        onChange={() => setIsAiGenerated(!isAiGenerated)}
        key={isAiGenerated}
      />
      <div className="thumbnailContainer">
        <img src={thumbnail.url} />
      </div>
      {!componentData.loading && (
        <>
          <Form
            key={isAiGenerated}
            method="POST"
            url="/api/component/thumbnail"
            buttonText="Create Thumbnail"
            callback={(res) => {
              thumbnailCallback(res);
            }}
            inputs={{
              ai_generated: {
                type: "hidden",
                value: isAiGenerated,
              },
              parent_id: {
                type: "hidden",
                value: props.parent_id,
              },

              prompt: {
                type: isAiGenerated ? "text" : "hidden",
                label: "Thumbnail Prompt",
                required: isAiGenerated,
                value: thumbnail.prompt || "",
              },

              size: {
                label: "Size",
                type: isAiGenerated ? "radio" : "hidden",
                options: ["wide", "tall", "square"],
                default: "square",
                required: isAiGenerated,
              },

              visual: {
                label: "Style",
                type: isAiGenerated ? "radio" : "hidden",
                options: ["natural", "vivid"],
                default: "natural",
                required: isAiGenerated,
              },

              hd: {
                label: "HD",
                type: isAiGenerated ? "switch" : "hidden",
              },

              file: {
                type: [isAiGenerated ? "hidden" : "file"],
                label: "Upload an Image",
                required: !isAiGenerated,
              },
            }}
          />
        </>
      )}

      {props.onRemove && (
        <button onClick={onRemove} className="removeBtn">
          Remove
        </button>
      )}
    </div>
  );
}
