import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Card } from "components/lib";
import Style from "./donate.view.scss";

export function Donate() {
  // used to update the URL
  const navigate = useNavigate();

  return (
    <div className="donate">
      <Card className="intro">
        <div>
          <h1>
            Thank you for your interest in supporting <b>tyro</b>
          </h1>
          <h2>
            Our donations are currently closed, <br />
            please check back soon.
          </h2>
        </div>
      </Card>
    </div>
  );
}
