/***
 *
 *   CREATE AN ASSISTANT
 *
 **********/

import React, { useState, useEffect, useContext } from "react";
import Axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import {
  Grid,
  ExpandableCard,
  Form,
  OrganizationCard,
  ThumbnailComponent,
  MetadataComponent,
  FileComponent,
  Button,
  ViewContext,
  Header,
} from "components/lib";

// css styles
import Style from "./ai_creator.view.scss";

///prop function
export function CreateAssistant(props) {
  const context = useContext(ViewContext);

  // for the redirect after form submission
  const navigate = useNavigate();

  //get the params
  const params = useParams();

  // form state
  const [formData, setFormData] = useState({});

  //store if the form is in edit mode
  const [isEditMode, setEditMode] = useState(!!params.assistant_id);

  const [assistant, setAssistant] = useState({});

  //post the form data to the api depending on if it is a new prompt or an edit
  const postUrl = isEditMode
    ? `/api/ai/assistant/${params.assistant_id}`
    : "/api/ai/assistant";

  useEffect(() => {
    //only used for populating the form when editing a prompt
    if (params.assistant_id) {
      Axios.get(`/api/ai/assistant/${params.assistant_id}`)
        .then((response) => {
          const data = response.data.data;
          if (data) setFormData(data);
          if (data.components) addComponentFromResponse(data.components);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [params]);

  //added to all inputs to update the state

  function assistantCallback(res) {
    context.snack.show(`Assistant ${isEditMode ? "updated" : "created"}`);

    if (!isEditMode) {
      setEditMode(true);
      navigate(`/ai/create/assistant/${res.data.data.id}`);
      setAssistant(res.data.data);
    }
  }

  async function deleteAssistant(id) {
    await Axios({
      method: "delete",
      url: `/api/ai/assistant/${id}`,
      params: {
        id: id,
      },
    })
      .then((response) => {
        context.snack.show("assistant deleted");
        console.log(response);
        setTimeout(function () {
          navigate("/ai/create");
        }, 1000);
      })
      .catch((error) => {
        context.snack.show("error deleting assistant");
      });
  }

  //COMPONENT MANAGEMENT
  const [currentComponents, setCurrentComponents] = useState([]);

  function addComponentFromResponse(components) {
    // const componentTypes = components.map(  (component) => component.component_type );
    setCurrentComponents(components);
  }

  const addComponent = (component) => {
    if (
      !currentComponents.some(
        (currentComponent) =>
          currentComponent.component_type === component.component_type
      )
    ) {
      setCurrentComponents((prevComponents) => [...prevComponents, component]);
    }
  };

  const removeComponent = (component) => {
    if (currentComponents.includes(component)) {
      setCurrentComponents((prevComponents) =>
        prevComponents.filter((comp) => comp !== component)
      );
      context.snack.show(friendlyName(component.component_type) + " removed");
    }
  };

  const renderComponent = (component) => {
    switch (component.component_type) {
      case "thumbnail":
        return (
          <ThumbnailComponent
            key={component.component_id}
            parent_id={params.assistant_id}
            component_id={component.component_id}
            parent_type="assistant"
            onRemove={() => removeComponent(component)}
          />
        );
      case "file":
        return (
          <FileComponent
            key={component.component_id}
            parent_id={params.assistant_id}
            component_id={component.component_id}
            parent_type="assistant"
            onRemove={() => removeComponent(component)}
          />
        );

      default:
        return null;
    }
  };

  const friendlyName = (name) => {
    return name.replace(/\b\w/g, (l) => l.toUpperCase()).replace("Ai", "AI");
  };

  return (
    <div className={Style.ai_creator}>
      <Header>
        {isEditMode ? (
          <button
            className="red-btn delete-btn"
            onClick={() => deleteAssistant(params.assistant_id)}
          >
            DELETE
          </button>
        ) : null}
      </Header>
      <Grid cols={2}>
        <OrganizationCard title="Ai Creator">
          <ExpandableCard
            title={`Assistant: ${formData.title || "New Assistant"}`}
            expanded={formData?.components?.length === 0}
          >
            {" "}
            <Form
              key={formData.id}
              url={postUrl}
              method={isEditMode ? "patch" : "post"}
              buttonText="Save"
              callback={(res) => {
                assistantCallback(res);
              }}
              inputs={{
                active: {
                  label: "Publish",
                  type: "switch",
                  default: formData.active || false,
                },
                type: {
                  label: "assistant type",
                  type: "select",
                  default: formData.type || "persona",
                  options: [
                    { value: "persona", label: "Persona" },
                    { value: "manager", label: "Manager" },
                  ],
                  required: true,
                },
                model: {
                  label: "model",
                  type: "select",
                  default: formData.model || "gpt-3.5-turbo",
                  options: [
                    { value: "gpt-3.5-turbo", label: "GPT 3.5 Turbo" },
                    { value: "gpt-4-turbo", label: "GPT 4 Turbo" },

                    { value: "gpt-4o", label: "GPT 4 o" },
                  ],
                  required: true,
                },
                title: {
                  label: "Title",
                  type: "text",
                  required: true,
                  value: formData.title,
                  placeholder: "Give your assistant a title",
                  errorMessage: "Please give the assistant a title",
                },
                description: {
                  label: "Description",
                  type: "textarea",
                  required: true,
                  value: formData.description,
                  placeholder: "Give your assistant a description",
                  errorMessage: "Please give the assistant a description",
                },
                greeting: {
                  label: "Greeting",
                  type: "text",
                  required: true,
                  value: formData.greeting,
                  placeholder: "Give your assistant a greeting",
                  errorMessage: "Please give the assistant a greeting",
                },

                instructions: {
                  label: "Instructions",
                  type: "textarea",
                  required: true,
                  value: formData.instructions,
                  placeholder: "Instructions for your assistant to follow",
                  errorMessage: "Please give the assistant instructions",
                },

                persona: {
                  label: "Persona",
                  type: "textarea",
                  required: false,
                  value: formData.persona,
                  placeholder: "The persona of the assistant",
                  errorMessage: "Please give the assistant a persona",
                },

                response_format: {
                  label: "Response Format",
                  type: "select",
                  default: formData.response_format || "text",
                  options: [
                    { label: "text", value: "text" },
                    { label: "json", value: "json" },
                  ],
                  default: "text",
                },
              }}
            />
          </ExpandableCard>

          {/* Area to load components */}
          <div>
            {currentComponents.map((component, index) => (
              <div key={component}>
                <ExpandableCard title={friendlyName(component.component_type)}>
                  {renderComponent(component)}
                </ExpandableCard>
              </div>
            ))}
          </div>
        </OrganizationCard>
        <OrganizationCard title="Components">
          {isEditMode ? (
            <>
              <Button
                text="Thumbnail"
                icon="plus"
                color="#d95565"
                size={13}
                action={() => addComponent({ component_type: "thumbnail" })}
              />

              <Button
                text="Files"
                icon="plus"
                color="#d95565"
                size={13}
                action={() => addComponent({ component_type: "file" })}
              />
            </>
          ) : null}
        </OrganizationCard>
      </Grid>

      <div id="snackbar"> </div>
    </div>
  );
}
