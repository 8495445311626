/***
 *
 *   DiscoverCard
 *   Container Used on the discover page
 *
 *   PROPS
 *   title: title string (optional)
 *   subtitle:subtitle string (optional)
 *   shadow: apply a box shadow
 *   center: center align the card
 *   transparent: remove the bg color
 *   restrictWidth: restrict the width of the card on large screens
 *
 **********/

import { Loader, ClassHelper, Button } from "components/lib";

import Style from "./discoverContainer.module.scss";

export function DiscoverContainer(props) {
  const cardStyle = ClassHelper(Style, {
    loading: props.loading,
    className: props.className,
    transparent: props.transparent,
    outline: props.outline,
    bgimage: props.bgimage,
    dark: props.dark,
    white: props.white,
    shadow: props.shadow,
    center: props.center,
  });

  return (
    <section className={cardStyle}>
      {props.title && (
        <header className={Style.header}>
          <h2 className={Style.title}>{props.title}</h2>
          {props.subtitle && <h3>{props.subtitle}</h3>}
        </header>
      )}
      {props.loading ? <Loader /> : props.children}
      {props.goto && (
        <Button text="View all" className={Style.viewBtn} goto={props.goto} />
      )}{" "}
    </section>
  );
}
