/***
 *
 *   FOOTER (homepage)
 *   Static homepage footer
 *
 **********/

import { Animate, Row, Content, Link, Logo } from "components/lib";
import Style from "./footer.module.scss";

export function Footer(props) {
  return (
    <Animate type="slideup">
      <footer className={Style.footer}>
        <Row>
          <Content>
            <img src={"./images/logo-color.png"} width="100px" alt="Logo" />
            <br />
            <nav className={Style.nav}>
              <Link
                url="/terms"
                text="Terms"
                className={Style.link}
                color="dark"
              />
              <Link
                url="/privacy"
                text="Privacy"
                className={Style.link}
                color="dark"
              />
              <Link
                url="/contact"
                text="Contact"
                className={Style.link}
                color="dark"
              />
            </nav>
          </Content>
        </Row>
      </footer>
    </Animate>
  );
}
