/***
 *
 *   VIEW
 *   The view houses global components that are common to all views
 *   (notification, modal), handles errors and renders the layout
 *
 **********/

import { useState, createContext, useEffect } from "react";
import {
  AppLayout,
  AuthLayout,
  HomeLayout,
  DocsLayout,
  OnboardingLayout,
  Modal,
  Notification,
  Snack,
  Loader,
  useNavigate,
} from "../lib";

import "./scss/normalize.scss";
import "./scss/view.scss";
import "./scss/typography.scss";
import "./scss/form.scss";

export const ViewContext = createContext();

// Utility function to debounce a function call (performance )
function debounce(func, wait) {
  let timeout;
  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}

export function View(props) {
  // this handles the scroll direction, we use this to modify the navigation and header on the chat page
  // it's here because this is the common parent of both the nav and the chat view

  // state
  let [scrollDirection, setScrollDirection] = useState("up");

  useEffect(() => {
    if (props.title !== "Space") return; // only run this on the chat page

    let lastKnownScrollPosition = 0;
    let ticking = false;

    const handleScroll = () => {
      lastKnownScrollPosition = window.scrollY;

      if (!ticking) {
        window.requestAnimationFrame(() => {
          if (lastKnownScrollPosition > window.oldScrollY) {
            if (lastKnownScrollPosition > 20) {
              setScrollDirection("down");
            }
          } else {
            setScrollDirection("up");
          }
          window.oldScrollY = lastKnownScrollPosition;
          ticking = false;
        });

        ticking = true;
      }
    };

    // Debounce the scroll handler to improve performance
    const debouncedHandleScroll = debounce(handleScroll, 10);

    window.addEventListener("scroll", debouncedHandleScroll);
    return () => window.removeEventListener("scroll", debouncedHandleScroll);
  }, []);

  const navigate = useNavigate();

  const [notification, setNotification] = useState({
    visible: "hide",
    autoclose: true,
  });

  const [snack, setSnack] = useState({
    visible: "hide",
    autoclose: true,
  });
  const [modal, setModal] = useState({});
  const [loading, setLoading] = useState(false);

  // layouts
  const layouts = {
    app: AppLayout,
    home: HomeLayout,
    auth: AuthLayout,
    docs: DocsLayout,
    onboarding: OnboardingLayout,
  };

  // set title & layout
  document.title = props.title;
  let Layout = props.layout ? layouts[props.layout] : AppLayout;

  if (!props.display) return false;

  function showNotification(text, type, autoclose, format, icon) {
    setNotification({
      text: text,
      type: type,
      show: true,
      format: format,
      icon: icon,
      autoclose: autoclose,
    });

    if (autoclose) setTimeout(hideNotification, 2000);
  }

  function hideNotification() {
    setNotification({
      text: "",
      type: "",
      show: false,
      format: null,
      icon: null,
      autoclose: true,
    });
  }

  function showSnack(text, type) {
    setSnack({
      text: text,
      type: type,
      show: true,
    });

    setTimeout(hideSnack, 3000);
  }

  function hideSnack() {
    setSnack({
      text: "",
      type: "",
      show: false,
    });
  }

  function showModal(content, callback) {
    let data = { ...modal };

    if (content) {
      for (let key in content) data[key] = content[key];

      data.show = true;
      data.callback = callback;
    }

    setModal(data);
  }

  function hideModal(cancel, res) {
    // callback?
    if (!cancel && modal.callback) modal.callback(modal.form, res);

    // reset the modal
    setModal({
      title: null,
      text: null,
      buttonText: null,
      url: null,
      method: null,
      show: false,
    });
  }

  function handleError(err) {
    let message = "There was a glitch in the matrix – please try again";

    if (err) {
      message = err.toString();

      if (err.response) {
        if (err.response.data?.message) message = err.response.data.message;

        if (err.response.status) {
          switch (err.response.status) {
            case 401:
              navigate("/signin");
              break;

            case 404:
              navigate("/notfound");
              break;

            case 429:
              showNotification(err.response.data, "error", false);
              break;

            case 402: // payment required
              navigate("/account/upgrade?plan=" + err.response.data.plan);
              break;

            default:
              showNotification(message, "error", false);
              break;
          }
        }
      } else {
        showNotification(message, "error", false);
      }
    }
  }

  const context = {
    notification: {
      show: showNotification,
      hide: hideNotification,
      data: notification,
    },

    snack: {
      show: showSnack,
      hide: hideSnack,
      data: snack,
    },
    modal: {
      show: showModal,
      hide: hideModal,
      data: modal,
    },
    scrollDirection: scrollDirection,

    setLoading: (state) => setLoading(state),
    handleError: handleError,
    historyOpen: false,
  };

  return (
    <ViewContext.Provider value={context}>
      {notification.show && <Notification {...notification} />}

      {snack.show && <Snack {...snack} />}

      {loading && <Loader fullScreen />}

      {modal.show && <Modal {...modal} />}

      <Layout title={props.title} data={props.data} display={props.display}>
        {props.display}
      </Layout>
    </ViewContext.Provider>
  );
}
