/***
 *
 *   BILLING / PLAN
 *   Update the billing plan
 *
 **********/

import React, { useContext } from "react";
import {
  AuthContext,
  AccountCard,
  Card,
  Form,
  Message,
  usePlans,
  Header,
} from "components/lib";

export function BillingPlan(props) {
  const plans = usePlans();
  const context = useContext(AuthContext);

  return (
    <AccountCard
      loading={plans.loading || props.subscription.loading}
      className={props.className}
      title="Account: Billing"
    >
      <Card restrictWidth>
        {props.subscription?.data?.object && (
          <Message
            type="info"
            title="Your Billing Cycle"
            text={`${props.subscription.data.object.current_period_start} to ${props.subscription.data.object.current_period_end}`}
          />
        )}

        <Form
          inputs={{
            plan: {
              label: "Your subscription plan",
              type: "select",
              required: true,
              default: plans?.data?.active,
              options: plans?.data?.list,
            },
          }}
          url="/api/account/plan"
          method="PATCH"
          buttonText="Update Plan"
          callback={(res) => {
            context.update({
              plan: res.data.data.plan,
              subscription: "active",
            });
            props.onUpdate();
          }}
        />
      </Card>
    </AccountCard>
  );
}
