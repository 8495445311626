/***
 *
 *   ORGANIZATION Page
 *   view all organizations that belong to an acccount
 *
 **********/

import { useParams } from "react-router-dom";
import { useContext, useState } from "react";

import {
  OrganizationCard,
  Header,
  Grid,
  Animate,
  OrganizationTable,
  useAPI,
  AuthContext,
  Button,
} from "components/lib";

export function District(props) {
  const params = useParams();
  const authContext = useContext(AuthContext);

  //state
  const [rosterStatus, setRosterStatus] = useState([]);

  const account_id = authContext.user.account_id;

  // get the current account data
  //const account = useAPI("/api/account/") || [];

  // get the current roster settings
  let rosterSettings = useAPI("/api/account/settings");

  // get district
  const distrct = useAPI("/api/account/district") || [];

  // get schools
  const schools = useAPI("/api/account/schools") || [];

  //const schoolList = ["name", "contact_name", "city", "active"];
  const schoolList = ["name"];

  // get the user count
  const userCount = useAPI("/api/account/" + account_id + "/user/count") || [];

  return (
    <>
      <Header />

      <header className="content-header">
        <h1>
          District: <span>{distrct.data?.name}</span>
        </h1>
        <p>
          The top level organization of your account encompasses the school
          district, along with all the schools and users within it. These
          entities are created automatically during the rostering process.
        </p>
        {!distrct.data?.contact_name ? null : (
          <>
            <b>Primary Contact:</b>
            <br />
            <b>Name: </b> {distrct.data?.contact_name}
            <br />
            <b>Email: </b> {distrct.data?.contact_email}
            <br />
            {distrct.data?.contact_title
              ? <b>Title: </b> + (distrct.data?.contact_title).toUpperCase()
              : ""}
            <br />
          </>
        )}
      </header>

      <Animate>
        <OrganizationCard loading={distrct.loading} title="About">
          {!distrct.data?.location_state ? null : (
            <div>
              <b>Location:</b>
              <br />
              State: {distrct.data?.location_state}
              <br />
              City: {distrct.data?.location_city}
              <br />
            </div>
          )}

          <Grid cols={2}>
            <OrganizationCard loading={userCount.loading} title="Users">
              District Admins: {userCount.data?.district_admin_count}
              <br />
              School Admins: {userCount.data?.school_admin_count}
              <br />
              Teachers: {userCount.data?.teacher_count}
              <br />
              Students:{userCount.data?.student_count}
              <br />
            </OrganizationCard>

            <OrganizationCard loading={rosterSettings.loading} title="Roster">
              <div>
                <b> Roster Service:</b> {rosterSettings.data?.roster_source}
              </div>
              <div>
                <b>Auto-sync: </b>
                {rosterSettings.data?.roster_autosync === 1 ? "true" : "false"}
                <br />
                <b>Last Update: </b>
                {new Date(
                  rosterSettings.data?.roster_last_sync
                ).toLocaleDateString() ?? "never"}
                <br />
              </div>

              <div>{rosterStatus} </div>
            </OrganizationCard>
          </Grid>
        </OrganizationCard>

        <OrganizationCard title={"Schools " + schools.data?.length}>
          <OrganizationTable
            search
            className="single_action"
            data={schools.data}
            loading={schools.loading}
            show={schoolList}
            link={{
              url: "/account/organization/school/",
              id: "id",
            }}
          />
        </OrganizationCard>
      </Animate>
    </>
  );
}
