import { History } from "views/app/history";
import { Help } from "views/dashboard/help";
import { OnboardingView } from "views/onboarding/onboarding";
import { Account } from "views/account";
import { Discover } from "views/app/discover";
import { Donate } from "views/app/donate";

const Routes = [
  {
    path: "/welcome",
    view: OnboardingView,
    layout: "onboarding",
    permission: "user",
    title: "Welcome",
  },
  {
    path: "/help",
    view: Help,
    layout: "app",
    permission: "user",
    title: "Get Help",
  },

  {
    path: "/discover",
    view: Discover,
    layout: "app",
    permission: "user",
    title: "Discover",
  },

  {
    path: "/donate",
    view: Donate,
    layout: "app",
    permission: "user",
    title: "Donate",
  },
];

export default Routes;
